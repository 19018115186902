
<template>
  <BreadCrumb PageTitle="Invited Users" />
  <div class="row" v-if="user">
    <div class="col-xxl-12">
      <InvitedContent :selectedEmail = "route.query.email"/>
    </div>
  </div>
</template>

<script setup>
// import { defineComponent } from "vue";
import { useRoute } from 'vue-router';
import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import InvitedContent from "../../components/Admin/Customer/InvitedContent.vue";

import { useAccountStore } from "@/stores"
import { storeToRefs } from 'pinia';
const route = useRoute();
const accountStore = useAccountStore();
const { user } = storeToRefs(accountStore);
</script>