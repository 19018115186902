<template>
    <div class="md:flex justify-between  gap-4 ">
        <div class="md:w-1/2 bg-white text-black" >
            <div class="px-4 py-4  " variant="outlined" rounded="0">
                <div class="text-md justify-center items-center flex">
                    <i class="flaticon-atm mx-2 text-lg"></i>
                    <p>Recharge with CRYPTO (Transaction fees)</p>
                </div>
                <div class="text-sm px-2 pt-4">
                    <span class="flex py-1">
                        <i class="flaticon-check mx-2"></i>
                        1. Choose the currency ( Bitcoin, USDT, Litecoin or BUSDT ).
                    </span>
                    <span class="flex py-1">
                        <i class="flaticon-check mx-2"></i>
                        2. Input the amount you want to deposit, and create the order.
                    </span>
                    <span class="flex py-1">
                        <i class="flaticon-check mx-2"></i>
                        3. Make your payment using the right network.
                    </span>
                    <span class="flex py-1">
                        <i class="flaticon-check mx-2"></i>
                        4. Can take between 5 - 30 minutes before the payment is processed.
                    </span>
                </div>
            </div>
        </div>
        <div class="md:mt-0 md:w-1/2 text-sm bg-white text-black">
            <div class="border-0 " variant="outlined" rounded="0">
                <div class="px-4 py-4">
                    <div class="">
                        <div class="w-full text-sm">
                            <v-col class="flex justify-between px-0 py-0 my-2">
                                <p>Crypto currencies</p>
                                <p>Input Amount (USD)</p>
                            </v-col>
                            <v-col class="px-0 p-0">
                                <div class="flex">
                                    <div class="w-1/2">
                                        <v-combobox v-model="selectedItem" :items="items" placeholder="Select"
                                            rounded="0" variant="outlined" style="outline: none;" density="compact"
                                            class="bg-white text-black text-sm" :hide-no-data="false">
                                            <template v-slot:no-data>
                                                <v-list-item density>
                                                    <v-list-item-title density="compact" variant="disabled">
                                                        <p class="text-sm text-gray-600 text-center">Not found</p>
                                                    </v-list-item-title>
                                                </v-list-item>
                                            </template>
                                        </v-combobox>
                                    </div>
                                    <div class="w-1/2 ml-5">
                                        <v-text-field type="number" v-model="amount.input" variant="outlined"
                                            rounded="0" @change="inputChangeHandler" density="compact"
                                            style="outline:none;"></v-text-field>
                                    </div>
                                </div>
                            </v-col>
                        </div>

                        <div class="w-full text-sm">
                            <v-col class="flex justify-between px-0 py-0 my-2">
                                <p>Min Amount (Coin)</p>
                                <p>Estimated Amount (Coin)</p>
                            </v-col>
                            <v-col class="px-0 py-0">
                                <div class="flex">
                                    <div class="w-1/2">
                                        <v-text-field type="number" v-model="amount.min" variant="outlined" rounded="0"
                                            density="compact" readonly style="outline:none;"></v-text-field>
                                    </div>
                                    <div class="w-1/2 ml-5">
                                        <v-text-field type="number" v-model="amount.est" variant="outlined" rounded="0"
                                            density="compact" readonly style="outline:none"></v-text-field>
                                    </div>
                                </div>
                            </v-col>
                        </div>
                        <div class="flex">
                            <div class="w-1/2 pr-2">
                                <p class=" py-2">Coupon Code:</p>
                                <v-text-field v-model="couponCode" variant="outlined" rounded="0"
                                            density="compact" style="outline:none"></v-text-field>
                            </div>
                            <div class="w-1/2 flex justify-center items-end">
                                <v-btn class="bg-dark text-light mt-2 text-xs shadow-lg" @click="openDialogHandler"
                                    :data-bs-toggle="amount.est > amount.min ? 'modal' : ''"
                                    data-bs-target="#transactionInfoModal">
                                        Create Transaction
                                </v-btn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>
        <TransactionInfoModal v-if="selectedItem" :transferInfo="transferInfo" />
    </div>
</template>

<script setup>
import { ref, watchEffect } from 'vue';
import { usePaymentStore } from '@/stores';
import { storeToRefs } from 'pinia';
import TransactionInfoModal from './Modals/TransactionInfoModal.vue';
const paymentStore = usePaymentStore()
const payment = storeToRefs(paymentStore)
var selectedItem_prev = ""
const selectedItem = ref(null)
const couponCode = ref('')
const name = process.env.VUE_APP_VUE_NAME
const items = ref([])
const amount = ref({
    input: 0,
    min: 0,
    est: 0
})
watchEffect(() => {
    paymentStore.getCurrencies()
}, [])

watchEffect(() => {
    if(name === 'global') {
        payment.currencies.value.forEach(element => {
            if(element.currency !== 'USDT' || element.network !== 'BSC') {
                items.value.push(element.currency + " / " + element.network)
            }
        });
    }
    else items.value = payment.currencies.value.map(item => item.currency + " / " + item.network)
    selectedItem.value = items.value[0]
}, [payment.currencies.value])
watchEffect(() => {
    // Perform side effects when selectedItem changes
    if (selectedItem.value !== null && selectedItem.value !== undefined) {
        const [selected_currency, selected_network] = selectedItem.value.split(' / ')
        const currency = payment.currencies.value.find(item => item.currency === selected_currency && item.network === selected_network)
        if (currency !== undefined) {
            amount.value.min = currency.min_amount
            amount.value.est = currency.conversion_rate * amount.value.input
        }

    }
    if (!items.value.find(item => item == selectedItem.value)) {
        selectedItem.value = selectedItem_prev
    }
    else {
        selectedItem_prev = selectedItem.value
    }
}, [selectedItem.value]);

const inputChangeHandler = () => {
    const [selected_currency, selected_network] = selectedItem.value.split(' / ')

    const currency = payment.currencies.value.find(item => item.currency === selected_currency && item.network === selected_network)
    if (currency !== undefined)
        amount.value.est = currency.conversion_rate * amount.value.input
}
const transferInfo = ref(null)
const openDialogHandler = () => {
    transferInfo.value = null

    if (amount.value.est > amount.value.min) {
        paymentStore.createTransaction(selectedItem.value.split(' / ')[1], selectedItem.value.split(' / ')[0], amount.value.input, couponCode.value, amount.value.est).then(res => {
            if (res) {
                transferInfo.value = res
                paymentStore.loadTransactionHistory()
            }
        })
    }

}
watchEffect(() => {
    amount.value.input = Math.max(0, amount.value.input)
}, [amount.value])
</script>