<template>
    <v-data-table :headers="headers" :items="filteredProxies" item-key="name" items-per-page="-1"
        class="border h-[500px] bg-white text-black min-h-[500px]" style="font-size: 13px;" :style="`height: ${computedHeight}px;`">

        <template v-slot:[`item.no`]="{ index }">
            {{ index + 1 }}
        </template>
        <template v-slot:[`item.modem`]="{ item }">
            <div class="flex items-center">
                <v-icon icon="mdi-circle" size="15" :color="item.status === 'CONNECTED' && item.modem_status === true ? 'green' : 'red'"
                    class="mr-2"></v-icon>
                {{ item.modem }}
            </div>
        </template>
        <template v-slot:[`item.ports`]="{ item }">
            <v-icon icon="mdi-alpha-h-circle-outline" color="green"></v-icon>
            {{ item.http_port }} /
            <v-icon icon="mdi-alpha-s-circle-outline" color="green"></v-icon>
            {{ item.sock_port }}
        </template>
        <template v-slot:[`item.pos`]="{item}">
            {{ item.proxyType === 'XPROXY' ? item.position : item.imei }}
        </template>
        <template v-slot:[`item.checkbox`]="{ item }">
            <div @click="toggleSelection(item)" class="ml-auto align-self-center "
                style="width: 20px; height: 20px; cursor: pointer;">
                <input :checked="isSelected(item)" class="form-check-input shadow-none" type="checkbox" />
            </div>
        </template>
        <template v-slot:[`item.usage_status`]="{ item }">
            {{ item.active === 'inactive' ? 'PAUSED' : item.usage_status }}
        </template>
        <!-- <template v-slot:[`item.dl_bytes`]="{ item }">
            {{ item.dl_bytes }} MB
        </template>
        <template v-slot:[`item.all_bytes`]="{ item }">
            {{ item.all_bytes }} MB
        </template> -->
        <template v-slot:[`item.bytes`]="{ item }">
            <div v-if="item.modem_type==='X_PROXY'">
                <div :style="checkDownloadLimit(item, item.http_dl_bytes) ? 'color: red' : ''">
                    HTTP: 
                    {{ item.http_dl_bytes }} MB
                </div>
                <div :style="checkDownloadLimit(item, item.sock_dl_bytes) ? 'color: red' : ''">
                    SOCKS5:  
                    {{ item.sock_dl_bytes }} MB
                </div>
            </div>
            <div v-else-if="item.modem_type==='PROXYSMART'">
                <div :style="checkDownloadLimit(item, item.dl_bytes) ? 'color: red' : ''">
                    {{ item.dl_bytes }}
                </div>
            </div>
        </template>
        <template v-slot:[`item.buttonBar`]="{item}">
            <v-menu offset-y location="bottom">
                <template v-slot:activator="{ props, on, isActive }">
                    <v-btn v-bind="props" v-on="{ ...on }" density="compact"
                        :style="isActive ? 'border-bottom-left-radius: 0px;border-bottom-right-radius: 0px;' : ''"
                        style="outline: none; font-size: 10px; " class="w-32 z-10 bg-dark text-light">
                        Actions
                        <v-icon icon="mdi-chevron-down" size="14"></v-icon>
                    </v-btn>
                </template>
                <v-list class="w-32 flex-1 flex flex-col px-0 py-0  z-0"
                    style="border-top-left-radius: 0px;border-top-right-radius: 0px">
                    <v-btn v-for="(button, index) in actionButtons" :key="index" @click="clickAction(item, button)"
                        rounded="0" class="text-center bg-dark text-light"
                        style="font-size: 10px; width: 128px; outline: none; border-bottom: 1px solid #345;"
                        :data-bs-toggle="button === 'Update' && 'modal'"
                        :data-bs-target="button === 'Update' && item.modem_type === 'X_PROXY'?'#xproxyUpdateModal':'#proxysmartUpdateModal'">
                        {{button === 'Active' ? item.active === 'active' ? 'Pause' : 'Resume' : button }}
                    </v-btn>
                </v-list>
            </v-menu>
            <!-- <v-btn
                @click="clickAction(item)" rounded="0" class="text-center bg-dark text-light "
                style="font-size: 10px; width: 80px; outline: none; border-bottom: 1px solid #345; height: 30px"
                :data-bs-toggle="'modal'"
                :data-bs-target="item.modem_type === 'X_PROXY'?'#xproxyUpdateModal':'#proxysmartUpdateModal'"
                >Update</v-btn> -->
        </template>
    </v-data-table>
    <UpdateDialoagXProxy :item="selectedItem" @updated="updatedHandler"/>
    <UpdateDialogProxySmart :item="selectedItem" @updated="updatedHandler"/>
</template>

<script setup>
import { ref, watchEffect, defineProps, defineExpose, onMounted, onBeforeUnmount } from 'vue';
import { useAdminProxyStore, useAlertStore } from "@/stores"
import UpdateDialoagXProxy from './Modals/UpdateDialoag-XProxy.vue';
import UpdateDialogProxySmart from './Modals/UpdateDialog-ProxySmart.vue';
import { storeToRefs } from 'pinia';
const adminProxyStore = useAdminProxyStore()
const { xProxies, smartProxies } = storeToRefs(adminProxyStore)
const filteredProxies = ref([])
const filteredProxies1 = ref([])

const actionButtons = ref(
    ["Update", "Active"]
)

const props = defineProps({
    selectedSaleStatus: {
        type: Object, // Assuming user object has a name property
        required: true
    },
    selectedServerName: {
        type: Object, // Assuming user object has a name property
        required: true
    },
    selectedServerType: {
        type: Object, // Assuming user object has a name property
        required: true
    },
});


const headers = ref(
    
);

const checkDownloadLimit = (item, value) => {
    if(item.modem_type==='X_PROXY') {
        if(item.downloadLimitType !== 'unlimited' && item.downloadLimitTimeUnit.toUpperCase() === "DAILY" && parseFloat(item.downloadLimitAmount) <= parseFloat(value)) {
            return true
        }
        else return false
    } else {
        if(Number(value) !== 0 && item.quotaDirType === 'Download' && item.quotaTimeUnit.toUpperCase() === "DAILY") {
            const regex = /(\d+(\.\d+)?)\s*(\w+)/;
            const matches = value.match(regex);
            if(matches[3]?.toUpperCase() === 'MB' && parseFloat(matches[1]) >= parseFloat(item.quotaAmount)) return true
            else if(matches[3]?.toUpperCase() === 'GB' && (parseFloat(matches[1]) * 1024) >= parseFloat(item.quotaAmount)) return true
            else return false

        } else return false
    }
}

const selectedSaleStatus = ref('All');

const offsetHeight = 400; // Adjust this value as needed

const computedHeight = ref(window.innerHeight - offsetHeight);

const handleResize = () => {
  computedHeight.value = window.innerHeight - offsetHeight;
};

onMounted(() => {
  window.addEventListener('resize', handleResize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
});

const selectedItems = ref([])
const selectedItem = ref(null)
let updateInfoDialogFlag = ref(false)

const clickAction = (item, button) => {
    switch (button) {
        case "Update":
            selectedItem.value = item
            updateInfoDialogFlag = true;
            break
        case "Active":
            adminProxyStore.updateAction(item.uuid)

    }
}
const updatedHandler = () => {
    updateInfoDialogFlag.value = false
}

watchEffect(() => {

    if (props.selectedServerType === 'X_PROXY') {
        filteredProxies.value = xProxies.value.filter(proxy =>
            (proxy.usage_status.toUpperCase() === selectedSaleStatus.value.toUpperCase() || selectedSaleStatus.value === 'All') &&
            (proxy.modem_type === props.selectedServerType)
        );
        headers.value = [
        { title: 'No', align: 'center', key: 'no' },
        { title: '', align: 'center', key: 'checkbox' },
        { title: 'Modem', align: 'center', key: 'modem' },
        { title: 'Position', align: 'center', key: 'position' },
        { title: 'Ports', align: 'center', key: 'ports' },
        // { title: 'UL Counter', align: 'center', key: 'ul_bytes' },
        // { title: 'DL Counter', align: 'center', key: 'dl_bytes' },
        // { title: 'All Counter', align: 'center', key: 'all_bytes' },
        { title: 'Download', align: 'center', key: 'bytes' },
        // { title: 'Counter Updated', align: 'center', key: 'counter_updated' },
        { title: 'Authentication User', align: 'center', key: 'auth_user' },
        { title: 'Authorization IPs', align: 'center', key: 'auth_ips' },
        { title: 'Usage Status', align: 'center', key: 'usage_status' },
        // { title: 'Status', align: 'center', key: 'status' },
        { title: 'Customer', align: 'center', key: 'customer_name' },
        { title: 'Action', align: 'center', key: 'buttonBar' },
    ]
    }
    else if (props.selectedServerType === 'PROXYSMART') {
        filteredProxies.value = smartProxies.value.filter(proxy =>
            (proxy.usage_status.toUpperCase() === selectedSaleStatus.value.toUpperCase() || selectedSaleStatus.value === 'All') &&
            (proxy.modem_type === props.selectedServerType)
        );
        
        headers.value = [
        { title: 'No', align: 'center', key: 'no' },
        { title: '', align: 'center', key: 'checkbox' },
        { title: 'Modem', align: 'center', key: 'modem' },
        { title: 'IMEI', align: 'center', key: 'imei' },
        { title: 'Ports', align: 'center', key: 'ports' },
        // { title: 'UL Counter', align: 'center', key: 'ul_bytes' },
        // { title: 'DL Counter', align: 'center', key: 'dl_bytes' },
        // { title: 'All Counter', align: 'center', key: 'all_bytes' },
        { title: 'Download', align: 'center', key: 'bytes' },
        // { title: 'Counter Updated', align: 'center', key: 'counter_updated' },
        { title: 'Authentication User', align: 'center', key: 'auth_user' },
        { title: 'Authorization IPs', align: 'center', key: 'auth_ips' },
        { title: 'Usage Status', align: 'center', key: 'usage_status' },
        // { title: 'Status', align: 'center', key: 'status' },
        { title: 'Customer', align: 'center', key: 'customer_name' },
        { title: 'Action', align: 'center', key: 'buttonBar' },
    ]
    }
    else if (props.selectedServerType === 'ALL') {
        filteredProxies.value = smartProxies.value.filter(proxy =>
            (proxy.usage_status.toUpperCase() === selectedSaleStatus.value.toUpperCase() || selectedSaleStatus.value === 'All') &&
            (proxy.modem_type === 'PROXYSMART')
        );
        filteredProxies1.value = xProxies.value.filter(proxy =>
            (proxy.usage_status.toUpperCase() === selectedSaleStatus.value.toUpperCase() || selectedSaleStatus.value === 'All') &&
            (proxy.modem_type === 'X_PROXY')
        );
        filteredProxies1.value.forEach(proxy => {
            filteredProxies.value.push({...proxy, proxyType: 'XPROXY'})
        });
        headers.value = [
        { title: 'No', align: 'center', key: 'no' },
        { title: '', align: 'center', key: 'checkbox' },
        { title: 'Modem', align: 'center', key: 'modem' },
        { title: 'IMEI Or Position', align: 'center', key: 'pos' },
        { title: 'Ports', align: 'center', key: 'ports' },
        // { title: 'UL Counter', align: 'center', key: 'ul_bytes' },
        // { title: 'DL Counter', align: 'center', key: 'dl_bytes' },
        // { title: 'All Counter', align: 'center', key: 'all_bytes' },
        { title: 'Download', align: 'center', key: 'bytes' },
        // { title: 'Counter Updated', align: 'center', key: 'counter_updated' },
        { title: 'Authentication User', align: 'center', key: 'auth_user' },
        { title: 'Authorization IPs', align: 'center', key: 'auth_ips' },
        { title: 'Usage Status', align: 'center', key: 'usage_status' },
        // { title: 'Status', align: 'center', key: 'status' },
        { title: 'Customer', align: 'center', key: 'customer_name' },
        { title: 'Action', align: 'center', key: 'buttonBar' },
    ]
    }
}, [xProxies.value, smartProxies.value, selectedSaleStatus.value, props.selectedServerType])


const isSelected = (item) => {
    return selectedItems.value.includes(item);
};
const toggleSelection = (item) => {
    const index = selectedItems.value.indexOf(item);
    if (index === -1) {
        selectedItems.value.push(item)
    } else {
        selectedItems.value.splice(index, 1)
    }
};

watchEffect(() => {
    selectedSaleStatus.value = props.selectedSaleStatus
}, [props.selectedSaleStatus])


const updateActive = (item) => {
    adminProxyStore.updateAction(item.uuid)
}

const deleteProxies = () => {

    // Your logic to delete proxies
    if (selectedItems.value.find(item => item.usage_status.toUpperCase() === 'USED')) {
        const alertStore = useAlertStore()
        alertStore.warning("Can't delete used proxies")
        return
    }
    adminProxyStore.deleteProxy(selectedItems.value.map(item => item.uuid)).then(() => {
        selectedItems.value = []
        adminProxyStore.loadDisProxies(props.selectedServerName)
    })

}
defineExpose({
    deleteProxies
});
</script>
