<template>
  <div class="">
      <div class="flex justify-between items-center my-2 flex-wrap">
        <div class="flex flex-wrap gap-2 py-2">
          <div class="w-1/5 pr-5 py-2 min-w-[200px]">
              Referrer Email
              <v-combobox v-model="selectedReferrerName" :items="Referrers" placeholder="Select" rounded="0"
                  variant="outlined" style="outline: none;" density="compact" :hide-no-data="false">
                  <template v-slot:no-data>
                      <v-list-item density>
                          <v-list-item-title density="compact" variant="disabled">
                              <p class="text-sm text-gray-600 text-center">Not found</p>
                          </v-list-item-title>
                      </v-list-item>
                  </template>
              </v-combobox>
          </div>
          <div class="float-left  px-2 py-2 w-36">
            From:
            <VueDatePicker  v-model="fromDate" format='dd/MM/yyyy' class="text-black bg-white"></VueDatePicker >
          </div>
            <div class="float-left px-2 py-2 h-20 w-36">
              To:
              <VueDatePicker  v-model="toDate" format='dd/MM/yyyy' class="text-black bg-white"></VueDatePicker >
          </div>
          <v-btn icon="mdi-magnify" size="small" style="outline: none;" @click="searchFilter" class="bg-dark text-light mt-auto mb-[10px]"></v-btn>
          <v-btn size="small" style="outline: none;" @click="exportCSV" class="bg-dark text-light mt-auto mx-2 mb-[15px]">Export</v-btn>
        </div>
        <div class="my-3">
          Total Spent: {{ totalAmount }}
        </div>
      </div>
      <v-data-table :headers="headers" :items="Users" 
          item-key="name" class="border h-[500px] bg-white text-black min-h-[500px]" 
          items-per-page="-1"
          style="font-size: 13px;" :style="`height: ${computedHeight}px;`">
          <template v-slot:[`item.no`]="{ index }">
              {{ index + 1 }}
          </template>
          <template v-slot:[`item.tglink`]="{ item }">
              <a v-if="item.tglink" class="flex flex-row items-center gap-x-2 px-3" :href="formatTgLink(item.tglink)" target="_blank">
                  <img class="w-[30px] h-[30px]" src="../../../assets/tg.png" />
              </a>
          </template>
          <template v-slot:[`item.checkbox`]="{ item }">
              <div @click="toggleSelection(item)" class="ml-auto align-self-center"
                  style="width: 20px; height: 20px; cursor: pointer;">
                  <input :checked="isSelected(item)" class="form-check-input shadow-none" type="checkbox" />

              </div>
          </template>
          <template v-slot:[`item.transactions`]="{ item }">
              <router-link
                  class="text-white"
                  :to="{ name: 'AdminTransactionPage', query: { email: item.email }}"
              >
                  View
              </router-link>
          </template>
          <template v-slot:[`item.buttonBar`]="{ item }">
              <v-menu offset-y location="bottom">
                  <template v-slot:activator="{ props, on, isActive }">
                      <v-btn v-bind="props" v-on="{ ...on }" color="#234" density="compact"
                          :style="isActive ? 'border-bottom-left-radius: 0px;border-bottom-right-radius: 0px;' : ''"
                          style="outline: none; font-size: 10px; " class="w-32 z-10 bg-dark text-light">
                          Actions
                          <v-icon icon="mdi-chevron-down" size="14"></v-icon>
                      </v-btn>
                  </template>
                  <v-list class="w-32 flex-1 flex flex-col px-0 py-0  z-0"
                      style="border-top-left-radius: 0px;border-top-right-radius: 0px">
                      <v-btn v-for="(button, index) in actionButtons" :key="index" @click="clickAction(item, button)"
                          rounded="0" class="text-center bg-dark text-light"
                          style="font-size: 10px; width: 128px; outline: none; border-bottom: 1px solid #345;"
                          :data-bs-toggle="(button === 'Update' || button === 'Invited Users') && 'modal'"
                          :data-bs-target="button === 'Update' ? '#customerUpdateModal' : '#myReferralModal'">
                          {{ button }}
                      </v-btn>
                  </v-list>
              </v-menu>
          </template>
      </v-data-table>
  </div>
  <div>
    <UpdateInfoDialog :item="selectedItem" @updated="updatedHandler" />

  </div>
</template>
<script setup>
import { ref, onMounted, defineProps, onBeforeUnmount, watchEffect } from 'vue';
import { useAdminUserStore } from "@/stores"
import { storeToRefs } from 'pinia';
import UpdateInfoDialog from "./Modals/UpdateInfoDialog.vue";
import VueDatePicker from '@vuepic/vue-datepicker';

const props = defineProps({
    selectedEmail: {
        type: String, // Assuming user object has a name property
    },
});


const headers = ref(
  [
    { title: 'No', align: 'center', key: 'no' },
    { title: '', align: 'center', key: 'checkbox' },
    { title: 'User Name', align: 'center', key: 'user_name' },
    { title: 'Full Name', align: 'center', key: 'name' },
    { title: 'Email', align: 'center', key: 'email' },
    { title: 'Role', align: 'center', key: 'role' },
    { title: 'Balance', align: 'center', key: 'balance' },
    { title: 'Address', align: 'center', key: 'address' },
    { title: 'City', align: 'center', key: 'city' },
    { title: 'Country', align: 'center', key: 'country' },
    { title: 'IP', align: 'center', key: 'ipAddress' },
    { title: 'Total Spent', align: 'center', key: 'total_spent' },
    { title: 'Referrer', align: 'center', key: 'referrer' },
    { title: 'Telegram', align: 'center', key: 'tglink' },
    { title: 'Transactions', align: 'center', key: 'transactions', width: '150px', minWidth: '150px' },
    { title: 'Actions', align: 'center', key: 'buttonBar' },
  ]
);

const updateInfoDialogFlag = ref(false)

const adminUserStore = useAdminUserStore()
const { Users } = storeToRefs(adminUserStore)
const selectedItems = ref([]);
const selectedItem = ref(null);
const totalAmount = ref(0)

const nowDate = new Date();
const oneMonthAgoDate = new Date(nowDate);
oneMonthAgoDate.setMonth(nowDate.getMonth() - 1);

const fromDate = ref(oneMonthAgoDate);
const toDate = ref(nowDate);

const selectedReferrerName = ref(props.selectedEmail ? props.selectedEmail : "All")
const { Referrers } = storeToRefs(adminUserStore)
// adminUserStore.loadReferrals(selectedReferrerName.value, )

watchEffect(() => {
  if(Users.value){
    totalAmount.value = 0
    Users.value.map(user => {
      totalAmount.value += user.total_spent
    })
  }
})

onMounted(() => {
  const from = fromDate.value;
  from.setHours(0, 0, 0, 0);
  const to = toDate.value;
  to.setHours(24, 0, 0, 0)
  adminUserStore.loadReferrals({fromDate: from, toDate: to, selectedReferrerName: selectedReferrerName.value});
  window.addEventListener('resize', handleResize);
});

const exportCSV = () => {
  const filename = "data.csv"
  const csvRows = [];
  const headers = Object.keys(Users.value[0]);
  csvRows.push(headers.join(",")); // Add headers

  Users.value.forEach(row => {
      const values = headers.map(header => {
          const value = row[header];
          return typeof value === "string" ? `"${value.replace(/"/g, '""')}"` : value; // Handle quotes in strings
      });
      csvRows.push(values.join(","));
  });

  const csvString = csvRows.join("\n");
  
  // Create a Blob and trigger download
  const blob = new Blob([csvString], { type: "text/csv" });
  const url = URL.createObjectURL(blob);
  
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
}

const searchFilter = () => {
  const from = fromDate.value;
  from.setHours(0, 0, 0, 0);
  const to = toDate.value;
  to.setHours(24, 0, 0, 0)
  adminUserStore.loadReferrals({fromDate: from, toDate: to, selectedReferrerName: selectedReferrerName.value});
}

const offsetHeight = 300; // Adjust this value as needed

const computedHeight = ref(window.innerHeight - offsetHeight);

const handleResize = () => {
  computedHeight.value = window.innerHeight - offsetHeight;
};

onBeforeUnmount(() => {
window.removeEventListener('resize', handleResize);
});

const isSelected = (item) => {
  return selectedItems.value.includes(item); 
};

const toggleSelection = (item) => {
  const index = selectedItems.value.indexOf(item);
  if (index === -1) {
      selectedItems.value.push(item);
  } else {
      selectedItems.value.splice(index, 1);
  }
};


const actionButtons = ref(
  ["Update"]
)
const clickAction = (item, button) => {
  switch (button) {
      case "Update":
          selectedItem.value = item
          updateInfoDialogFlag.value = true
          break
  }
}

const formatTgLink = (tglink) => {
    if(tglink) {
    const cleanedID = tglink.replace(/(@|https?:\/\/t\.me\/)/g, "").trim();
    const telegramUrl = `https://t.me/${cleanedID}`;
    return telegramUrl;
    } else return ''
}


const updatedHandler = () => {
  updateInfoDialogFlag.value = false
  adminUserStore.loadReferrals()
}
</script>