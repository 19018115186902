<template>
    <div class="modal fade" id="serverAddModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered  w-[700px] ">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5">Add New Server</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body ">
                        <div class="float-left w-1/2 px-2 pb-2 modal-item">
                            Server Name
                            <input v-model="modemName" placeholder="Input modem name" type="text"
                                class="form-control shadow-none rounded-0 text-black">
                        </div>
                        <div class="float-left w-1/2 px-2 pb-2 modal-item">
                            Server URL
                            <input v-model="domain" placeholder="Input domain" type="text"
                                class="form-control shadow-none rounded-0 text-black">
                        </div>
                        <div class="float-left w-1/2 px-2 pb-2 modal-item">
                            Country
                            <v-select v-model="country" :items="countryList" placeholder="Select country"
                                variant="outlined" density="compact" rounded="0" class=""></v-select>
                        </div>
                        <div class="float-left w-1/2 px-2 pb-2 modal-item">
                            ISP
                            <v-select v-model="isp" :items="ispList" placeholder="Select ISP" variant="outlined"
                                density="compact" rounded="0" class=""></v-select>
                        </div>
                        <div class="float-left w-1/2 px-2 pb-2 modal-item">
                            City
                            <input v-model="city" placeholder="Input city" type="text"
                                class="form-control shadow-none rounded-0 text-black">
                        </div>
                        <div class="float-left w-1/2 px-2 pb-2 modal-item">
                            Server Type
                            <v-select v-model="selectedModemType" :items="modemTypes" density="compact"
                                variant="outlined" placeholder="Select Server type" rounded="0"
                                style="padding-top: 0px;padding-bottom: 0px;">
                            </v-select>
                        </div>
                        <div class="float-left w-1/2 px-2 pb-2 modal-item">
                            Username
                            <input v-model="username" placeholder="Input username" type="text"
                                class="form-control shadow-none rounded-0 text-black">
                        </div>
                        <div class="float-left w-1/2 px-2 pb-2 modal-item">
                            Password
                            <input v-model="password" placeholder="Input password" type="text"
                                class="form-control shadow-none rounded-0 text-black">
                        </div>
                        <div class="float-left w-full px-2 pb-2 modal-item">
                            Notes
                            <textarea v-model="notes" class="form-control shadow-none !leading-5 rounded-0 text-black" />
                        </div>
                        <div class="clear-both"></div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" :disabled="buttonDisableFlag" class="btn btn-primary"
                        @click="createServer">Create</button>
                </div>
            </div>
        </div>
    </div>
</template>



<script setup>
import { ref, defineEmits } from "vue";

import { useAdminServerStore } from "@/stores"
import { watchEffect } from "vue";

const adminServerStore = useAdminServerStore()

const modemName = ref("");
const domain = ref("");
const country = ref("");
const city = ref("");
const isp = ref("");
const username = ref("");
const password = ref("");
const notes = ref("");
const selectedModemType = ref("X_PROXY");
const modemTypes = ref(["X_PROXY", "PROXYSMART"]);
const emit = defineEmits(['inInput', 'submit'])
const buttonDisableFlag = ref(false)


const countryList = ref([])
const ispList = ref([])
const createServer = () => {
    // Call your function to add the values
    // For example:
    const server = {
        modemName: modemName.value,
        domain: domain.value,
        country: country.value,
        city: city.value,
        isp: isp.value,
        username: username.value,
        password: password.value,
        modemType: selectedModemType.value,
        notes: notes.value,
    };
    buttonDisableFlag.value = true
    // Here you can call another function to handle the server creation
    adminServerStore.createServer(server).then(()=>{
        buttonDisableFlag.value=false
        emit('close');

    })
};


watchEffect(async () => {
    countryList.value = await adminServerStore.getCountryList()
    if (countryList.value.length > 0) {
        country.value = countryList.value[0]
    }
}, [])

watchEffect(async () => {
    if (country.value) {
        ispList.value = await adminServerStore.getISPLIst(country.value)
        if (ispList.value.length > 0) {
            isp.value = ispList.value[0]
        }
    }
}, [country.value])


</script>

<style scoped>
.clear-both {
    clear: both;
}
</style>