
<template>
  <BreadCrumb PageTitle="Disconnected Proxy" />
  <div class="row" v-if="user">
    <div class="col-xxl-12">
      <DisProxyContent />
    </div>
  </div>
</template>

<script setup>

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import DisProxyContent from "../../components/Admin/Proxy/DisProxyContent.vue";

import { useAccountStore } from "@/stores"
import { storeToRefs } from 'pinia';
const accountStore = useAccountStore();
const { user } = storeToRefs(accountStore);
</script>