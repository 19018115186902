<template>
    <div class="modal fade" id="proxysmartAddModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered w-[700px]">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5">Add New Proxy (ProxySmart)</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body flex flex-wrap" v-if="availableModemList">
                    <div class="float-left w-1/2 px-2 py-2 modal-item">
                        Modem Nick:
                        <div class="flex items-center">
                            <v-select v-model="selectedModemNick" :items="availableModemList.map(item => item.Nick)"
                                variant="outlined" rounded="0" density="compact">
                            </v-select>
                            <v-icon class="ml-2 cursor-pointer" icon="mdi-refresh" @click="refreshModemList"></v-icon>
                        </div>
                    </div>
                    <div class="float-left w-1/2 px-2 py-2 modal-item">
                        Modem IMEI:
                        <v-text-field v-model="selectedModemIMEI" readonly variant="outlined" rounded="0"
                            density="compact">
                        </v-text-field>
                    </div>
                    <div class="float-left w-1/2 px-2 py-2 modal-item">
                        <span @click="authType = 'username'" style="cursor: pointer;" class="flex items-center">
                            <!-- <div class="w-5 mr-4">
                                <input :checked="authType === 'username'" class="form-check-input shadow-none" type="checkbox" />
                            </div> -->
                            <span class="text-sm">Authentication User:</span>
                        </span>
                        <v-text-field v-model="userAuthentication" variant="outlined" density="compact" rounded="0"
                            :disabled="authType !== 'username'">
                            <template v-slot:append-inner>
                                <v-icon icon="mdi-refresh" @click="generateRandomCredentials"></v-icon>
                            </template>
                        </v-text-field>
                    </div>
                    <!-- <div class="float-left w-1/2 px-2 py-2">
                        <span @click="authType = 'ip'" style="cursor: pointer;" class="flex items-center py-2">
                            <div class="w-5 mr-4">
                                <input :checked="authType === 'ip'" class="form-check-input shadow-none" type="checkbox" />
                            </div>
                            <span class="text-sm">Authentication IPs:</span>
                        </span>
                        <v-text-field v-model="ipAuthentication" variant="outlined" density="compact" rounded="0" :disabled="authType !== 'ip'"></v-text-field>
                    </div> -->
                    <div class="float-left w-1/6 px-2 py-2 modal-item-small">
                        HTTP port
                        <v-text-field v-model="http_port" variant="outlined" density="compact" type="number" rounded="0"
                            style=""></v-text-field>
                    </div>
                    <div class="float-left w-1/6 px-2 py-2 modal-item-small">
                        Sock port
                        <v-text-field v-model="sock_port" variant="outlined" density="compact" rounded="0"
                            type="number"></v-text-field>
                    </div>
                    <div class="float-left w-1/6 px-2 py-2 modal-item-small">
                        Max conn
                        <v-text-field v-model="max_conn" variant="outlined" density="compact" type="number" rounded="0"
                            style=""></v-text-field>
                    </div>
                    <div class="float-left w-full px-2 py-2">
                        <div class=" mb-2">
                            OS Spoofing
                        </div>
                        <v-select v-model="selectedOSType" :items="types" density="compact" item-title="name" rounded="0"
                        variant="outlined" style="padding-top: 0px;padding-bottom: 0px;">
                            <template v-slot:selection="{ item }"  >
                                <v-list-item :title="item.raw.name" density style="padding: 0;">
                                <template v-slot:prepend>
                                    {{item.name}}
                                </template>
                                </v-list-item>
                            </template>
                            <template v-slot:item="{ props, item }">
                                <v-list-item v-bind="props" :title="item.raw.name"   density="compact">
                                <template v-slot:prepend>
                                    {{ item.name }}
                                </template>
                                </v-list-item>
                            </template>
                        </v-select>
                    </div>
                    <div class="float-left w-full px-2 py-2">
                        Data Limits
                        <div class="flex items-center justify-between flex-wrap">
                            <v-radio-group v-model="quotaType" inline class="pr-5 modal-item">
                                <v-radio label="Unlimited" value="unlimited"></v-radio>
                                <v-radio label="Limited (MB)" value="limited"></v-radio>
                            </v-radio-group>
                            <div class="flex w-1/2 space-x-2 pl-2 modal-item" v-if="quotaType === 'limited'">
                                <v-select v-model="quotaDirType" :items="quotaDirTypes" variant="outlined" rounded="0"
                                    density="compact" class="w-5/12 modal-item-small"></v-select>
                                <v-text-field v-model="quotaAmount" variant="outlined" density="compact" rounded="0"
                                    type="number" style="" class="w-[100px] modal-item-small"></v-text-field>
                                <v-select v-model="quotaTimeUnit" :items="timeUnitTypes" variant="outlined" rounded="0"
                                    density="compact" class="w-1/3 modal-item-small"></v-select>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="float-left w-1/2 px-2 py-2">
                        Restriction Whitelist
                        <v-textarea v-model="whitelist" variant="outlined" density="compact" rows="2" no-resize
                            rounded="0"
                            placeholder="Please fill website list separate by comma (empty for ignore). Ex: *facebook.com,*.youtube.com"
                            aria-details="none" :height="40"></v-textarea>
                    </div> -->
                    <div class="float-left w-1/2 px-2 py-2 modal-item">
                        Restriction Blacklist
                        <v-textarea v-model="blacklist" variant="outlined" density="compact" rows="2" no-resize
                            rounded="0"
                            placeholder="Please fill website list separate by comma (empty for ignore). Ex: facebook.com, youtube.com'"></v-textarea>
                    </div>
                    <div class="float-left w-full px-2 py-2">
                        Proxy Download Speed Limits
                        <div class="flex items-center justify-between flex-wrap">
                            <v-radio-group v-model="bandLimitInType" inline>
                                <v-radio label="Unlimited" value="unlimited"></v-radio>
                                <v-radio label="Limited (mbps)" value="limited"></v-radio>
                            </v-radio-group>
                            <div class="flex w-1/2" v-if="bandLimitInType === 'limited'">
                                <v-text-field v-model="bandLimitInAmount" variant="outlined" density="compact"
                                    rounded="0" type="number" style=""></v-text-field>
                            </div>
                        </div>
                    </div>
                    <div class="float-left w-full px-2 py-2">
                        Proxy Upload Speed Limits
                        <div class="flex items-center justify-between flex-wrap">
                            <v-radio-group v-model="bandLimitOutType" inline>
                                <v-radio label="Unlimited" value="unlimited"></v-radio>
                                <v-radio label="Limited (mbps)" value="limited"></v-radio>
                            </v-radio-group>
                            <div class="flex w-1/2" v-if="bandLimitOutType === 'limited'">
                                <v-text-field v-model="bandLimitOutAmount" variant="outlined" density="compact"
                                    rounded="0" type="number" style=""></v-text-field>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-body" v-else>
                    Loading available modems...
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" :disabled="availableModemList ? buttonDisableFlag : true" class="btn btn-primary"
                        @click="createNewProxy">Create</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, watchEffect, defineEmits, defineProps } from 'vue';
import { useAdminProxyStore, useAlertStore } from "@/stores";

const emit = defineEmits(['inInput', 'submit']);
const adminProxyStore = useAdminProxyStore();
const props = defineProps({
    serverName: {
        type: Object, // Assuming user object has a name property
        required: true
    },
});
const selectedServerName = ref(props.serverName);
const availableModemList = ref(null);
const httpList = ref(null);
const socketList = ref(null);
const types = ref([
    {value: '', name: '--No Spoofing--'}, 
    {value: 'android:1', name: 'android:1, p0f compliand but slow'},
    {value: 'android:3', name: 'android:3, real Android, almost like Linux'},
    {value: 'android:4', name: 'android:4, real Android 14'},
    {value: 'macosx:3', name: 'macosx:3'},
    {value: 'macosx:4', name: 'macosx:4, real MacOSX 12.6 / iPhone 13 Pro Max'},
    {value: 'macosx:5', name: 'macosx:5, real MacOSX Ventura 13.5.1'},
    {value: 'ios:1', name: 'ios:1, p0f compliant'},
    {value: 'ios:2', name: 'ios:2, real lphone'},
    {value: 'ios:3', name: 'ios:3, real IPhone 12 max pro'},
    {value: 'windows:1', name: 'windows:1, real Windnows 10 64bit Server or Tiny'},
    {value: 'windows:4', name: 'windows:4, real Windows 10 and 11 64bit Desktop'}
])
const selectedOSType = ref({value: '', name: '--No Spoofing--'})

watchEffect(async () => {
    if (selectedServerName.value && selectedServerName.value !== 'All') {
        availableModemList.value = null;
        httpList.value = null;
        socketList.value = null;
        const result = await adminProxyStore.load_PS_AvailableModemList(selectedServerName.value);
        availableModemList.value = result.modemList;
        httpList.value = result.httpList;
        socketList.value = result.socketList;
        if (availableModemList.value?.length > 0) {
            selectedModemNick.value = availableModemList.value[0].Nick;
            while(httpList.value?.includes(http_port.value.toString())) http_port.value++;
            while(socketList.value?.includes(sock_port.value.toString())) sock_port.value++;
        }
        }
}, [selectedServerName.value]);

const refreshModemList = async () => {
    availableModemList.value = null;
    httpList.value = null;
    const result = await adminProxyStore.load_PS_AvailableModemList(selectedServerName.value);
    availableModemList.value = result.modemList;
    httpList.value = result.httpList;
    socketList.value = result.socketList;
    if (availableModemList.value?.length > 0) {
        selectedModemNick.value = availableModemList.value[0].Nick;
        while(httpList.value?.includes(http_port.value.toString())) http_port.value++;
        while(socketList.value?.includes(sock_port.value.toString())) sock_port.value++;
    }
} 

watchEffect(() => {
    selectedServerName.value = props.serverName;
}, [props.serverName]);

const buttonDisableFlag = ref(false);
const selectedModemNick = ref('');
const selectedModemIMEI = ref('');
const userAuthentication = ref('');
const ipAuthentication = ref('');
const http_port = ref(8101);
const sock_port = ref(5101);
const max_conn = ref(200);
const whitelist = ref('');
const blacklist = ref('');
const quotaAmount = ref(0);
const quotaTimeUnit = ref('Daily');
const bandLimitInAmount = ref(0);
const bandLimitOutAmount = ref(0);
const quotaType = ref('unlimited');

const quotaDirTypes = ref([ 'Download', 'Upload', 'Download+Upload'])
const quotaDirType = ref('Download')
const bandLimitInType = ref('unlimited');
const bandLimitOutType = ref('unlimited');
const timeUnitTypes = ref(['Daily', 'Monthly', 'Lifetime']);

function createNewProxy() {
    let temp_auth_user = userAuthentication.value;
    let temp_auth_ips = ipAuthentication.value;
    switch (authType.value) {
        case "username":
            temp_auth_ips = null;
            break;
        case "ip":
            temp_auth_user = null;
            break;
    }
    if(authType.value === "username"){
        const userpass = temp_auth_user.split(':');
        if(userpass[0].length < 4 || userpass[1].length < 4) {
            const alertStore = useAlertStore()
            alertStore.error("Please lengthen username and password to 4 characters or more");
            return
        }
    }
    const proxyRequest = {
        modemNick: selectedModemNick.value,
        modemIMEI: selectedModemIMEI.value,
        userAuthentication: temp_auth_user,
        http_port: parseInt(http_port.value),
        sock_port: parseInt(sock_port.value),
        max_conn: parseInt(max_conn.value),
        whitelist: whitelist.value,
        blacklist: blacklist.value,
        quotaAmount: parseInt(quotaAmount.value),
        quotaTimeUnit: quotaTimeUnit.value,
        bandLimitInAmount: parseInt(bandLimitInAmount.value),
        bandLimitOutAmount: parseInt(bandLimitOutAmount.value),
        quotaType: quotaType.value === "unlimited" ? 0 : 1,
        quotaDirType: quotaDirType.value,
        bandLimitInType: bandLimitInType.value === "unlimited" ? 0 : 1,
        bandLimitOutType: bandLimitOutType.value === "unlimited" ? 0 : 1,
        osType: selectedOSType.value?.value ? selectedOSType.value?.value : selectedOSType.value
    };
    
    buttonDisableFlag.value = true;
    adminProxyStore.createNewProxy(selectedServerName.value, proxyRequest).then(() => {
        adminProxyStore.loadProxies(selectedServerName.value);
        buttonDisableFlag.value = false;
        emit('close');
    });
}

function generateRandomCredentials() {
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let username = '';
    let password = '';
    for (let i = 0; i < 7; i++) {
        username += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    for (let i = 0; i < 7; i++) {
        password += Math.floor(Math.random() * 10); // Generates a random digit between 0 and 9
    }
    userAuthentication.value = `u${username}:p${password}`;
}

watchEffect(() => {
    http_port.value = Math.max(8000, Math.min(9000, http_port.value));
    // while(availableModemList.value.httpList.include(http_port.value)) http_port.value++;
    sock_port.value =  Math.max(5000, Math.min(6000, sock_port.value));
    // while(availableModemList.value.socketList.include(sock_port.value)) sock_port.value++;
    max_conn.value = Math.max(0, max_conn.value);
    quotaAmount.value = Math.max(0, quotaAmount.value);
    bandLimitInAmount.value = Math.max(0, bandLimitInAmount.value);
    bandLimitOutAmount.value = Math.max(0, bandLimitOutAmount.value);
}, []);

watchEffect(() => {
    if (selectedModemNick.value) {
        selectedModemIMEI.value = availableModemList.value?.find(item => item.Nick === selectedModemNick.value)?.IMEI;
    }
}, [selectedModemNick.value]);

const authType = ref('username');
generateRandomCredentials();
</script>
