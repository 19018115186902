import { defineStore } from 'pinia';
import { fetchWrapper } from '@/helpers';
import { useAlertStore } from '.';

const baseUrl = `${process.env.VUE_APP_VUE_API_URL}`;
function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
export const usePaymentStore = defineStore('payment', {
    state: () => ({
        currencies: [],
        transactions: [],
    }),
    actions: {
        async getCurrencies() {
            const alertStore = useAlertStore();
            try {
                const res = await fetchWrapper.get(`${baseUrl}/payment/crypto/get-currencies`);
                this.currencies = res;
                // await router.push(this.returnUrl || '/dashboard');
            } catch (error) {
                alertStore.error(error);
            }
        },
        async createTransaction(network, currency, amount, couponCode, est) {
            const alertStore = useAlertStore();
            try {
                const res = await fetchWrapper.post(`${baseUrl}/payment/crypto/create-transaction`,{network, currency, amount, couponCode, est});
                // const res = await fetchWrapper.post(`${baseUrl}/payment/crypto/test`,{network, currency, amount});
                return res
                // await router.push(this.returnUrl || '/dashboard');
            } catch (error) {
                alertStore.error(error);
            }
        },
        async insertTransaction(transaction){
            const date = new Date()
            this.transactions = [...this.transactions, {...transaction, createdDate: formatDate(date)}]
        },
        async loadTransactionHistory(data){
            const alertStore = useAlertStore();
            try {
                const res = await fetchWrapper.post(`${baseUrl}/payment/transaction-history`, data);
                if(data.from === 0 || !data.from)
                    this.transactions = res.transactions
                else this.transactions.push(...res.transactions);
                return res
                // await router.push(this.returnUrl || '/dashboard');
            } catch (error) {
                alertStore.error(error);
            }
        }
        // Other actions...
    },
});

export const updateTransactionHistory = ()=>{
    usePaymentStore().loadTransactionHistory()
}