<template>
  <div class="">
      <div class="flex justify-between items-center py-2 flex-wrap">
          <div class="flex flex-wrap gap-4 py-2">
              <SearchBar @server-selected="serverSelectedHandeler">
              </SearchBar>

              <div v-if="selectedServerName !== ''" class="w-56 pr-5 text-xs">
                  Usage Status
                  <v-select v-model="selectedSaleStatus" :items="saleStatusList" density="compact" variant="outlined"
                      rounded="0" placeholder="Select Sale Status" style="padding-top: 0px;padding-bottom: 0px;">
                  </v-select>
              </div>
          </div>
          <div v-if="selectedServerName !== ''">
              <v-btn icon="mdi-delete" size="small" style="outline: none;" @click="deleteProxies"
                  class="mr-5 bg-dark text-light"></v-btn>
          </div>
      </div>
      <div v-if="loading === true" class=" flex items-center justify-center absolute custom-style " >
          <img src="../../../assets/loading.gif" class="w-[50px]"/>
      </div>
      <TableView ref="tableViewRef" :selectedConnStatus="selectedConnStatus" :selectedServerName="selectedServerName"
          :selectedSaleStatus="selectedSaleStatus" :selectedServerType="selectedServerType"
          class="border bg-white text-black" />
  </div>
  <div>
      <XProxyAddNewOneDialog @close="addNewOneDialogFlag = false" :serverName="selectedServerName" />
      <ProxySmartAddNewOneDialog @close="addNewOneDialogFlag = false" :serverName="selectedServerName" />
  </div>
</template>
<script setup>
import { ref } from 'vue';
import { useAdminProxyStore, useAlertStore } from "@/stores"
import { storeToRefs } from 'pinia';
import SearchBar from './SearchBar.vue';
import TableView from "./TableViewDis.vue";
import XProxyAddNewOneDialog from "./Modals/AddNewOneDialog-XProxy.vue"
import ProxySmartAddNewOneDialog from "./Modals/AddNewOneDialog-ProxySmart.vue"


const adminProxyStore = useAdminProxyStore()
const { xProxies, smartProxies, loading } = storeToRefs(adminProxyStore)
const addNewOneDialogFlag = ref(false)
const selectedServerName = ref("All")
const selectedServerType = ref("X_PROXY")


const serverSelectedHandeler = (server) => {
  if (server) {
      selectedServerName.value = server.name
      selectedServerType.value = server.type
      adminProxyStore.loadDisProxies(server.name)
  }
}
const saleStatusList = ref(['All', 'AVAILABLE', 'USED'])
const selectedSaleStatus = ref('All')
const selectedConnStatus = ref('DISCONNECTED')


const tableViewRef = ref(null)
const deleteProxies = () => {
  tableViewRef.value.deleteProxies()
}
</script>

<style scoped>
.custom-style {
  width: -webkit-fill-available; /* Safari/WebKit */
  height: -webkit-fill-available; /* Safari/WebKit */
  width: -moz-available; /* Firefox */
  height: -moz-available; /* Firefox */
  width: fill-available; /* Standard syntax, for future compatibility */
  height: fill-available; /* Standard syntax, for future compatibility */
}
</style>