<template>
    <div class="modal fade" id="serverUpdateModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered  w-[700px] ">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5">Update Server</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body" v-if="selectedItem">
                    <div class="float-left w-1/2 px-2 pb-2 modal-item">
                        Modem Name
                        <input v-model="modemName" placeholder="Input modem name" type="text"
                            class="form-control shadow-none rounded-0 text-black">
                    </div>
                    <div class="float-left w-1/2 px-2 pb-2 modal-item">
                        Domain
                        <input v-model="domain" placeholder="Input domain" type="text"
                            class="form-control shadow-none rounded-0 text-black">
                    </div>
                    <div class="float-left w-1/2 px-2 pb-2 modal-item">
                        Country
                        <v-select v-model="country" :items="countryList" placeholder="Input country" variant="outlined"
                            rounded="0" density="compact"></v-select>
                    </div>
                    <div class="float-left w-1/2 px-2 pb-2 modal-item">
                        ISP
                        <v-select v-model="isp" :items="ispList" placeholder="Select ISP" variant="outlined" rounded="0"
                            density="compact"></v-select>
                    </div>
                    <div class="float-left w-1/2 px-2 pb-2 modal-item">
                        City
                        <input v-model="city" placeholder="Input city" type="text"
                            class="form-control shadow-none rounded-0 text-black">
                    </div>
                    <div class="float-left w-1/2 px-2 pb-2 modal-item">
                        Server Type
                        <v-select v-model="selectedModemType" :items="modemTypes" density="compact" variant="outlined"
                            rounded="0" placeholder="Select Server type" style="padding-top: 0px;padding-bottom: 0px;">
                        </v-select>
                    </div>
                    <div class="float-left w-1/2 px-2 pb-2 modal-item">
                        Username
                        <input v-model="username" placeholder="Input username" type="text"
                            class="form-control shadow-none rounded-0 text-black">
                    </div>
                    <div class="float-left w-1/2 px-2 pb-2 modal-item">
                        Password
                        <input v-model="password" placeholder="Input password" type="text"
                            class="form-control shadow-none rounded-0 text-black">
                    </div>
                    <div class="float-left w-full px-2 pb-2 modal-item">
                        Notes
                        <textarea v-model="notes" class="form-control shadow-none rounded-0 !leading-5 text-black" />
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" :disabled="buttonDisableFlag" class="btn btn-primary"
                        @click="UpdateServer">Update</button>
                </div>
            </div>
        </div>
    </div>
</template>



<script setup>
import { ref, defineProps, defineEmits } from "vue";

import { useAdminServerStore } from "@/stores"
import { watchEffect } from "vue";

const adminServerStore = useAdminServerStore()

const props = defineProps({
    item: {
        type: Object, // Assuming user object has a name property
        required: true
    },  
});

const selectedItem = ref(props.item)
const modemName = ref(selectedItem.value?.modemName);
const domain = ref(selectedItem.value?.domain);
const country = ref(selectedItem.value?.country);
const city = ref(selectedItem.value?.city);
const notes = ref(selectedItem.value?.notes);
const isp = ref(selectedItem.value?.isp);
const username = ref(selectedItem.value?.username);
const password = ref(selectedItem.value?.password);
const selectedModemType = ref(selectedItem.value?.modem_type);
const modemTypes = ref(["X_PROXY", "PROXYSMART"]);
const emit = defineEmits(['inInput', 'submit'])
const buttonDisableFlag = ref(false)
const UpdateServer = () => {
    // Call your function to add the values
    // For example:
    const server = {
        uuid: selectedItem.value?.uuid,
        modemName: modemName.value,
        domain: domain.value,
        country: country.value,
        city: city.value,
        isp: isp.value,
        username: username.value,
        password: password.value,
        modemType: selectedModemType.value,
        notes: notes.value
    };
    buttonDisableFlag.value = true
    // Here you can call another function to handle the server creation
    adminServerStore.updateServer(server).then(()=>{
        buttonDisableFlag.value=false
    })

};
const countryList = ref([])
const ispList = ref([])
watchEffect(async () => {
    countryList.value = await adminServerStore.getCountryList()
})

watchEffect(async () => {
    if (country.value) {
        ispList.value = await adminServerStore.getISPLIst(country.value)
    }
}, [country.value])
watchEffect(() => {
    if (props.item) {
        selectedItem.value = props.item
        modemName.value = selectedItem.value.modemName
        domain.value = selectedItem.value.domain
        country.value = selectedItem.value.country
        city.value = selectedItem.value.city
        isp.value = selectedItem.value.isp
        username.value = selectedItem.value.username
        password.value = selectedItem.value.password
        selectedModemType.value = selectedItem.value.modem_type
        notes.value = selectedItem.value.notes
    }
}, [props.item])
</script>



<style scoped>
.clear-both {
    clear: both;
}
</style>