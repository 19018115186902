<template>
  <div class="px-4 mt-4 pb-4 duration-500 bg-white" >

    <p class="py-2 text-black">Transaction History</p>
    <v-data-table :headers="headers" :items="transactions" show-expand item-value="date" item-key="name" items-per-page="-1"
      totalItems="100" hide-default-footer id="tablecontainer"
      class="border h-full bg-gray text-black min-h-[500px]" style="font-size: 13px;" :style="`height: ${computedHeight}px;`">
      <template v-slot:expanded-row="{ columns, item }">
        <td :colspan="columns.length" class="border-b py-4">
          <div class="flex justify-center items-center">
            <div v-html="item.description">
            </div>
          </div>
        </td>
      </template>
      <template v-slot:[`item.checkbox`]="{  }">
      </template>
      <template #bottom></template>
    </v-data-table>
  </div>
</template>
<script setup>

import { ref, watchEffect, onMounted, onBeforeUnmount } from 'vue';
import { usePaymentStore } from '@/stores';
import { storeToRefs } from 'pinia';
let count = 0;

const paymentStore = usePaymentStore()
const payment = storeToRefs(paymentStore)

const headers = ref(
  [
    { title: 'No', align: 'center', key: 'no' },
    { title: 'Created Date', align: 'center', key: 'createdDate' },
    { title: 'Transaction Type', align: 'center', key: 'transactionType' },
    { title: 'Status', align: 'center', key: 'status' },
    { title: 'Amount (USD)', align: 'center', key: 'amount' },
    { title: 'Amount (Coin)', align: 'center', key: 'amountCoin' },
    { title: 'Address', align: 'center', key: 'address' }
  ]
);
const offsetHeight = 500; // Adjust this value as needed

const computedHeight = ref(window.innerHeight - offsetHeight);

const handleResize = () => {
  computedHeight.value = window.innerHeight - offsetHeight;
};

onMounted(() => {
  window.addEventListener('resize', handleResize);

  count = 50;
  paymentStore.loadTransactionHistory({from: 0, count: 50});
  const element = document.getElementById('tablecontainer').children[0]
  element.addEventListener('scroll', onScroll)
});

const onScroll = (event) => {
  const table = event.target;
  if (table.scrollTop + table.clientHeight >= table.scrollHeight) {
    paymentStore.loadTransactionHistory({from: count, count: 20});
    count += 20;
  }
};

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
  const element = document.getElementById('tablecontainer').children[0]
  element.removeEventListener('scroll', onScroll)
});
const transactions = ref([...payment.transactions.value])
watchEffect(() => {
  transactions.value = [...payment.transactions.value].map((item, index) => {
    return {
      no: index + 1,
      createdDate: item.createdAt,
      transactionType: item.type,
      status: item.status,
      amount: String(Number(item.amount) + (item?.commission ? Number(item.commission) : 0) + (item?.costprice ? Number(item.costprice) : 0)) + item.currency,
      amountCoin: item.pay_amount + item.pay_currency,
      address: item.pay_address === undefined ? '' : item.pay_address,
      date: item.updatedAt,
      description: item.description
    }
  })
}, [payment.transactions.value])
// watchEffect(() => {
//   paymentStore.loadTransactionHistory()
// }, [])
</script>